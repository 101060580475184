import './App.css';
import "./contact.css";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useEffect, useState } from 'react';
import Home from './components/home'
import Booking from './components/booking';
import CookiesPage from './components/cookies';
import WarningSection from './components/warning-section';
import Contact from './components/contact';
import Events from './components/events';
import Admin from './components/admin';
import MyBooking from './components/mybooking';
import TheBoat from './components/theboat';
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-Z07L8RZM04');

const clientVersion = "09072021.1"

function App() {
  // ReactGA.pageview(window.location.pathname + window.location.search);

  /*live*/
  const promise = "pk_live_KoMZsMpVXosy5D4sS2c4Ub0c"
  const servPort = 3000
  const returnAddress = "https://boreas.paris/#/"

  //test DEV
  // const promise = "pk_test_oPJ5zJTjdWmjcTgCmSbw9Jiu"
  // const servPort = 3004
  // const returnAddress = `https://dev.boreas.paris/#/`

  // test LOCAL
  // const promise = "pk_test_oPJ5zJTjdWmjcTgCmSbw9Jiu"
  // const servPort = 3004
  // const returnAddress = `http://localhost:3000/#/`

  const [ratio, setRatio] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  })
  const [language, setLanguage] = useState("fr-FR")
  const [displayWarningPopup, setDisplayWarningPopup] = useState(true)
  const [displayLoadingScreen, setDisplayLoadingScreen] = useState(true)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [cookiePopUp, setCookiePopUp] = useState(true)
  const [activeEvents, setActiveEvents] = useState([])
  const [eventsLoaded, setEventLoaded] = useState(false)
  const [chosenDate, setChosenDate] = useState(new Date())
  const [selected, setSelected] = useState(null)

  // console.log("active events")
  // console.log(activeEvents)

  // console.log("chosendate :")
  // console.log(chosenDate)

  // console.log("SELECTED :")
  // console.log(selected)

  function refreshRatio() {
    setRatio({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    })
  }

  useEffect(() => {
    if (selected) {
      localStorage.removeItem('selected')
      localStorage.setItem('selected', JSON.stringify(selected))
    }
  }, [selected])

  let cacheSelected = JSON.parse(localStorage.getItem('selected'))
  if (cacheSelected) {
    console.log("parsed test : ", (cacheSelected))
  }

  useEffect(() => {
    setEventLoaded(true)
  }, [activeEvents])

  useEffect(() => {
    window.addEventListener('resize', refreshRatio)
    fetch(`https://boreasapi.cyrilmorin.fr:${servPort}/client-version`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.version === clientVersion) {
          console.log("client version matching")
        } else {
          alert("une version plus récente est disponible, le page va se rafraichir")
          setTimeout(() => {
            document.location.reload();
          }, 1000);
        }
      });
    document.title = "Le Boréas - Site Officiel"
    fetch(`https://boreasapi.cyrilmorin.fr:${servPort}/getactiveeventlist`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        const modifiedData = data.reduce((acc, e) => {
          const tmp = e.dates.map(date => {
            return (new Date(date))
          });
          e.dates = tmp
          acc.push(e)
          return acc
        }, [])
        const stared = data.filter(e => e.stared)
        const date = stared.length > 0 ? stared[0].dates[0] : new Date()
        setChosenDate(date)
        setActiveEvents(modifiedData)
      })
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home ratio={ratio} language={language}
              displayWarningPopup={displayWarningPopup}
              setDisplayWarningPopup={setDisplayWarningPopup}
              displayLoadingScreen={displayLoadingScreen}
              setDisplayLoadingScreen={setDisplayLoadingScreen}
              videoPlaying={videoPlaying}
              setVideoPlaying={setVideoPlaying}
              cookiePopUp={cookiePopUp}
              setCookiePopUp={setCookiePopUp}
              activeEvents={eventsLoaded ? activeEvents : []}
              setChosenDate={setChosenDate}
              chosenDate={chosenDate}
              setSelected={setSelected}
              servPort={servPort}
            />
          </Route>
          <Route exact path="/mybooking">
            <MyBooking ratio={ratio} language={language} servPort={servPort} />
          </Route>
          <Route exact path="/contact">
            <Contact ratio={ratio} language={language} servPort={servPort} />
          </Route>
          <Route exact path="/privatise">
            <Contact ratio={ratio} language={language} servPort={servPort} />
          </Route>
          <Route exact path="/cookiespage">
            <CookiesPage ratio={ratio} language={language} servPort={servPort} />
          </Route>
          <Route exact path="/the-boat">
            <TheBoat ratio={ratio} language={language} servPort={servPort} />
          </Route>
          <Route exact path="/booking">
            <Booking ratio={ratio}
              language={language}
              chosenDate={chosenDate}
              selected={cacheSelected}
              servPort={servPort}
              promise={promise}
              returnAddress={returnAddress}
            />
          </Route>
          <Route exact path="/events">
            <Events ratio={ratio}
              language={language}
              activeEvents={eventsLoaded ? activeEvents : []}
              setChosenDate={setChosenDate}
              chosenDate={chosenDate}
              selected={selected}
              setSelected={setSelected}
              servPort={servPort}
            />
          </Route>
          <Route exact path="/adminef56ezf35e6z4f356e456f4e64fe6f4z6e4fzfez">
            <Admin ratio={ratio} language={language} servPort={servPort} />
          </Route>
          <Route path="/">
            <WarningSection />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
